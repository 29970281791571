<template>
    <div class="page">
        <div class="title">法律顾问</div>

        <el-divider></el-divider>

        <!-- filter -->
        <div class="filter_box">
            <div class="filter_search"></div>
            <div class="filter_btns">
                <div
                    class="custom_button no_select"
                    @click="dialogFormVisible = true"
                >
                    添加
                </div>
            </div>
        </div>

        <!-- <el-image :src="require('@/assets/contract/ad.png')"></el-image> -->

        <!-- <div class="goods_list">
      <div class="goods_item" v-for="item in 2" :key="item">
        <div class="goods_item_head">
          <div class="goods_item_head_title">平台法律顾问咨询</div>
          <div class="goods_item_head_price">
            <span>¥</span>
            <span>1000.00</span>
            <span> /次</span>
          </div>
        </div>
        <div class="goods_item_content">
          服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍
        </div>
      </div>
    </div> -->

        <!-- table -->
        <div class="table_title">过往咨询</div>
        <el-table
            id="table"
            :data="tableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column prop="add_date" label="咨询时间"></el-table-column>
            <el-table-column prop="title" label="合同名称"></el-table-column>
            <el-table-column label="合同来源">
                <template>
                    <div>定制合同列表</div>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="反馈状态">
                <template v-slot:default="scope">
                    <div v-if="scope.row.status == 10">发起/待回复</div>
                    <div v-if="scope.row.status == 20">已回复</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="reply_date"
                label="反馈时间"
            ></el-table-column>
            <el-table-column label="最新合同" fixed="right">
                <template v-slot:default="scope">
                    <div class="operations">
                        <div v-if="scope.row.new_file_url" @click="openWindow(scope.row.new_file_url)">
                            下载
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            layout="prev, pager, next"
            :total="page.total"
            :page-size="page.size"
            :current-page.sync="page.page"
            @current-change="loadData"
        >
        </el-pagination>

        <el-dialog
            title="添加合同"
            width="40%"
            :visible.sync="dialogFormVisible"
        >
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item label="合同名称" prop="title">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="合同上传：" prop="ori_file_url">
                    <input
                        type="file"
                        accept="application/pdf"
                        @change="pdfUpload"
                    />
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="form.remark" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            search: {
                keyword: "",
                date: "",
            },
            tableData: [],
            page: {
                size: 15,
                page: 1,
                total: 0,
            },
            dialogFormVisible: false,
            form: {
                title: "",
                ori_file_url: "",
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: "请填写合同名称",
                        trigger: "blur",
                    },
                ],
                ori_file_url: [
                    {
                        required: true,
                        message: "请先上传合同",
                        trigger: "blur",
                    },
                ],
                remark: [
                    {
                        required: true,
                        message: "请填写备注信息",
                        trigger: "blur",
                    },
                ],
            },
            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        ...mapActions(["uploadFile"]),
        ...mapActions("contract", [
            "getLegalAdviserList",
            "postAddLegalAdviser",
        ]),

        loadData() {
            let loading = this.$loading();
            this.getLegalAdviserList({
                page: this.page.page,
            })
                .then((res) => {
                    console.log(res);
                    this.tableData = res.data.data.list;
                    this.page.total = res.data.data.total;
                    this.$nextTick(() => {
                        loading.close();
                    });
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                    this.$nextTick(() => {
                        loading.close();
                    });
                });
        },

        openWindow(url) {
            window.open(url, "_blank");
        },

        /**
         * 上传pdf
         */
        pdfUpload(file) {
            this.uploadFile(file.target.files[0])
                .then((res) => {
                    console.log(res);
                    this.form.ori_file_url = res.data.data.link;
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.dialogFormVisible = false;
                    let loading = this.$loading();
                    console.log(this.form);
                    this.postAddLegalAdviser(this.form)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.success(res.data.msg);
                            this.loadData();
                        })
                        .catch((e) => {
                            console.log(e);
                            loading.close();
                            this.$message.error(e.msg);
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.goods_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;

    .goods_item {
        width: calc(50% - 82px);
        background: #ffffff;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        padding: 40px 35px;

        .goods_item_head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 15px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40px;
                height: 3px;
                background: #4db9d5;
            }

            .goods_item_head_title {
                font-size: 20px;
                color: #1c2438;
            }

            .goods_item_head_price {
                > span:nth-child(1) {
                    font-size: 14px;
                    color: #f94d09;
                }

                > span:nth-child(2) {
                    font-size: 26px;
                    color: #f94d09;
                }

                > span:nth-child(3) {
                    font-size: 14px;
                    color: #1c2438;
                    position: relative;
                    bottom: 2px;
                }
            }
        }
    }

    .goods_item_content {
        font-size: 14px;
        color: #1c2438;
        line-height: 30px;
        margin-top: 50px;
    }
}

.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    .el-date-editor {
        height: 36px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
            height: 36px;
        }
    }

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_input {
            width: 350px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
